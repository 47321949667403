<div class="about-area bg-fef8ef ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image">
                                <img src="assets/img/about-img1.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image">
                                <img src="assets/img/about-img2.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image">
                                <img src="assets/img/about-img3.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image">
                                <img src="assets/img/about-img4.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Impara online</span>
                    <h2>Accresci le tue competenze, acquisisci le tue conoscenze e migliora le tue abilità ovunque tu sia</h2>
                    <p>tutti i nostri corsi sono svolti da formatori esperti che coinvolgono attivamente i partecipanti grazie ad esercitazioni e lavori di gruppo</p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-experience"></i> Docenti Esperti</span></li>
                        <li><span><i class="flaticon-time-left"></i> Accedi ovunque</span></li>
                        <li><span><i class="flaticon-tutorials"></i> Apprendi da remoto</span></li>
                        <li><span><i class="flaticon-self-growth"></i> Accedi quando vuoi</span></li>
                    </ul>
                    <a routerLink="/elenco-corsi" class="default-btn"><i class="flaticon-user"></i>Visualizza i Corsi<span></span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
</div>
