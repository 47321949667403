<div class="container">
    <div class="section-title">
        <span class="sub-title">Corsi per tutti</span>
        <h2>Con la nostra piattaforma e-learning è possibile seguire i nostri corsi sulla Sicurezza sul Lavoro in modo flessibile, efficace e interattivo​​</h2>
        <p>I nostri corsi online coprono una vasta gamma di temi legati alla Sicurezza sul Lavoro, che ti invitiamo a visionare nella sezione Elenco Corsi</p>
    </div>
    <div class="row">
        <div class="col-lg-3 col-sm-6 col-md-6">
            <div class="single-features-box">
                <div class="icon">
                    <i class="flaticon-brain-process"></i>
                </div>
                <h3>Impara dagli esperti del settore</h3>
                <p>L'apprendimento delle migliori competenze può portare a risultati straordinari.</p>
                <a routerLink="/elenco-corsi" class="link-btn">Inizia subito!</a>
            </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-md-6">
            <div class="single-features-box">
                <div class="icon">
                    <i class="flaticon-computer"></i>
                </div>
                <h3>Impara con il tuo ritmo</h3>
                <p>Ognuno preferisce divertirsi imparando al proprio ritmo e questo dà un grande risultato.</p>
                <a routerLink="/elenco-corsi" class="link-btn">Inizia subito!</a>
            </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-md-6">
            <div class="single-features-box">
                <div class="icon">
                    <i class="flaticon-shield-1"></i>
                </div>
                <h3>Impara dagli esperti del settore</h3>
                <p>I formatori esperti possono aiutare nell'apprendimento con i loro migliori approcci!</p>
                <a routerLink="/elenco-corsi" class="link-btn">Inizia Subito!</a>
            </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-md-6">
            <div class="single-features-box">
                <div class="icon">
                    <i class="flaticon-world"></i>
                </div>
                <h3>Divertiti a imparare da qualsiasi luogo</h3>
                <p>Siamo lieti di offrirti diverse opzioni, imparare da qualsiasi parte del mondo.</p>
                <a routerLink="/elenco-corsi" class="link-btn">Inizia Subito!</a>
            </div>
        </div>
    </div>
</div>
