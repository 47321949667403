<div class="container">
    <div class="section-title">
        <span class="sub-title">i corsi più richiesti</span>
        <h2>I più richiesti al momento</h2>
        <p>Esplora tutti i nostri corsi e scegli quelli adatti per iscriverti e iniziare a imparare con noi! Ti assicuriamo che non te ne pentirai mai!</p>
    </div>
    <div *ngIf="elencoCorsiInHome[0]" class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
            <div class="single-courses-box">
                <div class="courses-image">
                    <a routerLink="/dettaglio-corso/{{elencoCorsiInHome[0].tag}}" class="d-block image">
                        <img *ngIf="elencoCorsiInHome[0].isMiur==1" src="assets/img/miur.png" style="height: 50px; position: absolute; bottom: 20px; left: 25px;">
                        <img style="height:260px; object-fit: cover; width: 100%;" src="{{urlImage+elencoCorsiInHome[0].image}}" alt="image">
                    </a>
                    <div *ngIf="elencoCorsiInHome[0].costo_scontato<=0 && elencoCorsiInHome[0].costo>0 && elencoCorsiInHome[0].haveBuy==0" class="price shadow">€{{elencoCorsiInHome[0].costo}}</div>
                    <div *ngIf="elencoCorsiInHome[0].costo_scontato>0 && elencoCorsiInHome[0].haveBuy==0" class="price shadow">€{{elencoCorsiInHome[0].costo_scontato}}</div>

                </div>
                <div class="courses-content">
                    <h3><a routerLink="/dettaglio-corso/31">{{elencoCorsiInHome[0].titolo}}</a></h3>
                    <p class="textDescrizione">{{elencoCorsiInHome[0].descrizione}}</p>
                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                        <a routerLink="/dettaglio-corso/{{elencoCorsiInHome[0].tag}}" class="default-btn"><i class="flaticon-user"></i>Visualizza<span></span></a>
                        <li *ngIf="elencoCorsiInHome[0].durata>0" class="fw-bold"><i class="flaticon-time-left"></i> {{elencoCorsiInHome[0].durata+elencoCorsiInHome[0].durata_label}} </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="single-courses-box">
                <div class="courses-image">
                    <a routerLink="/dettaglio-corso/{{elencoCorsiInHome[1].tag}}" class="d-block image">
                        <img *ngIf="elencoCorsiInHome[1].isMiur==1" src="assets/img/miur.png" style="height: 50px; position: absolute; bottom: 20px; left: 25px;">
                        <img style="height:260px; object-fit: cover; width: 100%;" src="{{urlImage+elencoCorsiInHome[1].image}}" alt="image">
                    </a>
                    <div *ngIf="elencoCorsiInHome[1].costo_scontato<=0 && elencoCorsiInHome[1].costo>0 && elencoCorsiInHome[1].haveBuy==0" class="price shadow">€{{elencoCorsiInHome[1].costo}}</div>
                    <div *ngIf="elencoCorsiInHome[1].costo_scontato>0 && elencoCorsiInHome[1].haveBuy==0" class="price shadow">€{{elencoCorsiInHome[1].costo_scontato}}</div>
                </div>
                <div class="courses-content">
                    <h3><a routerLink="/dettaglio-corso/31">{{elencoCorsiInHome[1].titolo}}</a></h3>
                    <p class="textDescrizione">{{elencoCorsiInHome[1].descrizione}}</p>
                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                        <a routerLink="/dettaglio-corso/{{elencoCorsiInHome[1].tag}}" class="default-btn"><i class="flaticon-user"></i>Visualizza<span></span></a>
                        <li *ngIf="elencoCorsiInHome[1].durata>0" class="fw-bold"><i class="flaticon-time-left"></i> {{elencoCorsiInHome[1].durata+elencoCorsiInHome[1].durata_label}} </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="single-courses-box">
                <div class="courses-image">
                    <a routerLink="/dettaglio-corso/{{elencoCorsiInHome[2].tag}}" class="d-block image">
                        <img *ngIf="elencoCorsiInHome[2].isMiur==1" src="assets/img/miur.png" style="height: 50px; position: absolute; bottom: 20px; left: 25px;">
                        <img style="height:260px; object-fit: cover; width: 100%;" src="{{urlImage+elencoCorsiInHome[2].image}}" alt="image">
                    </a>
                    <div *ngIf="elencoCorsiInHome[2].costo_scontato<=0 && elencoCorsiInHome[2].costo>0 && elencoCorsiInHome[2].haveBuy==0" class="price shadow">€{{elencoCorsiInHome[2].costo}}</div>
                    <div *ngIf="elencoCorsiInHome[2].costo_scontato>0 && elencoCorsiInHome[2].haveBuy==0" class="price shadow">€{{elencoCorsiInHome[2].costo_scontato}}</div>
                </div>
                <div class="courses-content">
                    <h3><a routerLink="/dettaglio-corso/31">{{elencoCorsiInHome[2].titolo}}</a></h3>
                    <p class="textDescrizione">{{elencoCorsiInHome[2].descrizione}}</p>
                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                        <a routerLink="/dettaglio-corso/{{elencoCorsiInHome[2].tag}}" class="default-btn"><i class="flaticon-user"></i>Visualizza<span></span></a>
                        <li *ngIf="elencoCorsiInHome[2].durata>0" class="fw-bold"><i class="flaticon-time-left"></i> {{elencoCorsiInHome[2].durata+elencoCorsiInHome[2].durata_label}} </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12">
            <div class="courses-info">
                <p>Goditi i migliori metodi di apprendimento e raggiungi abilità di livello superiore! Sei il creatore della tua carriera e noi ti guideremo attraverso questo. <a routerLink="/contatti">Registrati è gratuito!</a>.</p>
            </div>
        </div>
    </div>
</div>
